import React from "react";
import FooterContent from "../HomePAgeContent/FooterContent";

export default function Footer() {
  return (
    <footer className="theme-footer-seven mt-120 md-mt-100">
      <div className="lg-container">
        <div className="container">
          <FooterContent />
        </div>
      </div>
    </footer>
  );
}
